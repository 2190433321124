import * as React from 'react';
import { graphql } from 'gatsby';
import ContextConsumer from '../helpers/context';

import Template from '../templates/contact';

const SkontaktujSiePage = ({ data }) => {
  
  const contact = data.med.pageContact;

  return (
    <ContextConsumer>
      {({ data, set }) => (
        <Template
          contextData={data}
          contextSet={set}
          langTo={'/en/contact'}
          data={contact}
        />
      )}
    </ContextConsumer>
  );
};

export const pageCoRobimyQuery = graphql`
  {
    med {
      pageContact(where: { id: "ckjtspcaw2okg0b53vcsq2p8a" }, locales: pl_PL) {
        address
        heading1
        heading2
        line1V1
        line1V2
        line1V3
        line2V1
        line2V2
        line2V3
        mapsUrl
        seoDescription
        seoImage {
          url
        }
        seoTitle
        title
        email
      }
    }
  }
`;

export default SkontaktujSiePage;
