import React, { useEffect } from 'react';
import styled from 'styled-components';
import SEO from '../components/Seo';

import Top from '../components/contact/Top';
import Info from '../components/contact/Info';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 120px);
  min-height: calc((var(--vh, 1vh) * 100) - 120px);

  @media only screen and (max-width: 600px) {
    min-height: calc(100vh - 90px);
    min-height: calc((var(--vh, 1vh) * 100) - 90px);
  }
`;

const Home = ({ data, contextSet, langTo }) => {
  useEffect(() => {
    contextSet({ langTo: langTo });
  }, []);

  return (
    <Wrapper>
      <SEO
        title={data.seoTitle}
        description={data.seoDescription}
        image={data.seoImage}
      />
      <Top data={data} />
      <Info data={data} />
    </Wrapper>
  );
};

export default Home;
