import React from 'react';
import styled from 'styled-components';

import H from '../styled/Heading';
import W from '../styled/Wrapper';

const Wrapper = styled(W)`
  padding-top: 100px;
`;

const Heading = styled(H)`
  margin-bottom: 6px;

  @media only screen and (max-width: 850px) {
    font-size: 24px;
    line-height: 26px;
  }

  @media only screen and (max-width: 810px) {
    font-size: 24px;
    line-height: 26px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 24px;
    line-height: 26px;
  }
`;

const Detail = styled(H)`
  margin-bottom: ${({ small, noM }) => (noM ? 0 : small ? '20px' : '40px')};
  display: ${({ small }) => (small ? 'block' : 'inline-block')};

  @media only screen and (max-width: 850px) {
    font-size: 34px;
    line-height: 1.1;
    max-width: ${({ maxW }) => maxW ? '460px' : '100%'};
    margin-bottom: ${({ small, noM }) => (noM ? 0 : small ? '20px' : '20px')};
  }

  @media only screen and (max-width: 810px) {
    font-size: 34px;
    line-height: 1.1;
  }

  @media only screen and (max-width: 600px) {
    font-size: 34px;
    line-height: 1.1;
  }
`;

const Info = ({ data }) => {
  return (
    <Wrapper>
      <Heading small size={4} as="h2">
        {data.heading1}
      </Heading>
      <Detail size={3} as="a" href={`mailto:${data.email}`}>
        {data.email}
      </Detail>

      <Heading size={4} as="h2" target="_blank">
        {data.heading2}
      </Heading>
      <Detail small size={3} as="a" href={data.mapsUrl} target="_blank" noM maxW>
        {data.address}
      </Detail>
    </Wrapper>
  );
};

export default Info;
