import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import W from '../styled/Wrapper';
import H from '../styled/Heading';

const Wrapper = styled(W)`
  padding-top: 15px;
`;

const Line1 = styled(H)`
  line-height: 1;

  @media only screen and (max-width: 850px) {
    font-size: 57px;
    line-height: 110%;
  }

  @media only screen and (max-width: 600px) {
    font-size: 40px;
    line-height: 44px;
  }
`;

const Line2 = styled(H)`
  line-height: 1;

  @media only screen and (max-width: 850px) {
    font-size: 57px;
    line-height: 110%;
  }

  @media only screen and (max-width: 600px) {
    font-size: 40px;
    line-height: 44px;
  }
`;

const Word = styled.span`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    height: 7px;
    width: 100%;
    background-color: ${({ theme }) => theme.colorMain};

    @media only screen and (max-width: 850px) {
      bottom: 3px;
      height: 3px;
    }
    
    @media only screen and (max-width: 600px) {
      bottom: 3px;
      height: 2px;
    }
  }
`;

const Top = ({ data }) => {
  const [line1, setLine1] = useState(data.line1V1);
  const [line2, setLine2] = useState(data.line2V1.split(' '));
  const interval = useRef(false);

  useEffect(() => {
    let currHeading = 1;

    interval.current = setInterval(() => {
      const nextHeading = currHeading === 3 ? 1 : currHeading + 1;

      setLine1(data[`line1V${nextHeading}`]);
      setLine2(data[`line2V${nextHeading}`].split(' '));

      currHeading = nextHeading;
    }, 1500);

    return () => {
      clearInterval(interval.current);
    };
  }, [data]);

  return (
    <Wrapper>
      <Line1 size={1}>{line1}</Line1>
      <Line2 size={1}>
        {line2.map((word, i) => {
          return (
            <React.Fragment>
              <Word key={i}>{word}</Word><span> </span>
            </React.Fragment>
          );
        })}
      </Line2>
    </Wrapper>
  );
};

export default Top;
